import request from "@/utils/request";

// 浏览信息
export function getIndustry(data) {
  return request({
    url: "/system/industryKnowledge/getIndustryKnowledgeList",
    method: "post",
    data
  });
}

// 浏览单个信息
export function getIndustryOne(data) {
  return request({
    url: "/system/industryKnowledge/getIndustryKnowledgeById",
    method: "get",
    params: data
  });
}

// 新增
export function addIndustry(data) {
  return request({
    url: "/system/industryKnowledge/importPersons",
    method: "post",
    data
  });
}

// 修改
export function editIndustry(data) {
  return request({
    url: "/system/industryKnowledge/updateIndustryKnowledge",
    method: "post",
    data
  });
}

// 删除
export function deleteIndustry(data) {
  return request({
    url: "/system/industryKnowledge/deleteById",
    method: "post",
    data
  });
}
