import request from '@/utils/request'

// 政府数据一览
export function getGovernmentList(data) {
  return request({
    url: '/system/government/getGovernmentList',
    method: 'post',
    data
  })
}

// 根据id查询
export function getGovernmentById(data) {
  return request({
    url: '/system/government/getGovernmentById',
    method: 'get',
    params:data
  })
}

// 删除政府数据
export function deleteGovernmentById(data) {
  return request({
    url: '/system/government/deleteGovernmentById',
    method: 'post',
    data
  })
}

// 新增政府数据
export function saveGovernment(data) {
  return request({
    url: '/system/government/save',
    method: 'post',
    data
  })
}

// 修改政府数据
export function updateGovernment(data) {
  return request({
    url: '/system/government/update',
    method: 'post',
    data
  })
}

// 根据id查询负责人信息
export function getGovernmentPrincipleById(data) {
  return request({
    url: '/system/government/getGovernmentPrincipleById',
    method: 'get',
    params:data
  })
}

// 查询上级机构
export function getHigherInstitutionsById(data) {
  return request({
    url: '/system/government/getHigherInstitutionsById',
    method: 'get',
    params:data
  })
}

// 查询下级机构
export function getLowInstitutionsById(data) {
  return request({
    url: '/system/government/getLowInstitutionsById',
    method: 'get',
    params:data
  })
}

// 删除机构负责人
export function deleteprincipleById(data) {
  return request({
    url: '/system/governmentPrinciple/deleteGovernmentPrincipleById',
    method: 'get',
    params:data
  })
}

// 新增机构负责人
export function governmentPrincipleSave(data) {
  return request({
    url: '/system/governmentPrinciple/save',
    method: 'post',
    data
  })
}

// 修改机构负责人
export function governmentPrincipleUpdate(data) {
  return request({
    url: '/system/governmentPrinciple/update',
    method: 'post',
    data
  })
}

// 删除上级机构
export function deleteGovernmentPrincipleById(data) {
  return request({
    url: '/system/government/deleteHighGovernmentById',
    method: 'get',
    params:data
  })
}

// 上级机构修改
export function governmentHighInstitutionUpdate(data) {
  return request({
    url: '/system/government/updateGovernmentAndHighInstitution',
    method: 'post',
    data
  })
}

// 上级机构新增
export function governmentHighInstitutionSave(data) {
  return request({
    url: '/system/government/saveHighGovernment',
    method: 'post',
    data
  })
}

// 上级机构智能查询
export function getByGovernmentNameList(data) {
  return request({
    url: '/system/government/getByGovernmentNameList',
    method: 'get',
    params:data
  })
}

// 编辑页机构查询
export function getGovernmentByName(data) {
  return request({
    url: '/system/government/getGovernmentByName',
    method: 'get',
    params:data
  })
}

// 导入
export function importCommercial(data) {
  return request({
    url: '/system/government/importProject',
    method: 'post',
    data
  })
}
